<template>
  <!-- Start Service Area  -->
  <v-row class="service-one-wrapper">
    <!-- Start Single Service  -->
    <v-col lg="6" md="12" sm="12" cols="12" v-for="(service, i) in airtrafficItem" :key="i">
      <div class="single-service service__style--siber" style="height: 33vh !important;">
        <router-link to="/service-details">
          <div class="service d-flex flex-row">
            <div class="icon" v-html="iconSvg(service.icon)"></div>
            <div class="content ml-5">
              <h3 class="heading-title">{{ service.title }}</h3>
              <p>
                {{ service.desc }}
              </p>
            </div>
          </div>
        </router-link>
      </div>
    </v-col>
    <!-- End Single Service  -->
  </v-row>
  <!-- Start Service Area  -->
</template>

<script>
import feather from "feather-icons";

export default {
  components: {
  },
  data() {
    return {
      airtrafficItem: [
        {
          icon: "filter",
          title: "Centralized Electronic Signature System",
          desc: "The Centralized Electronic Signature Application Platform can support the needs of electronic signatures which become a system hub (orchestration layer) and all applications that require a source of electronic signatures so that they can connect with multiple electronic certificate administrators (PSrE) and can also connect with global trust service providers outside Indonesia."
        },
        {
          icon: "file-text",
          title: "Certificate Authority Switching Engine",
          desc: "Centralized Electronic Signature Platform & Application can support electronic signature needs and become a hub system (orchestration layer) of all applications that require electronic signature services and can be connected to multiple electronic certificate organizers (PSrE)"
        },
        {
          icon: "monitor",
          title: "Billing system & Performance monitoring",
          desc: "The Centralized Electronic Signature Platforms offered have the following capabilities: Administration, Analytics and Reports, Business Application Enablement, Signing Options & Attachments, Management & Classification, Workflow & Process Management, Information Governance, Management and tracking, Integration Gateway, Monitoring & Logging, Maker/Checker Approval, Library Services, Records & Document Management, Content Creation & Collaboration."
        },
        {
          icon: "settings",
          title: "Signature Gateway API for flexible integration",
          desc: "Support stable connectivity and integration with other applications with different platforms"

        },
      ],
    };
  },
  methods: {
    iconSvg(icon) {
      return feather.icons[icon].toSvg();
    },
  },
};
</script>
<style scoped>
.centang {
  vertical-align: top;
  min-width: 17px;
  padding-top: 5px;
  margin-bottom: 10px !important;
}

.feather {
  width: 24px;
  height: 24px;
  stroke: currentColor;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
}
</style>
