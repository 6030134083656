<template>
  <div class="card pa-8">
    <div>
      <h3 style="font-size: 42px; font-weight: 700;">{{ year }}</h3>
      <h4 style="font-size: 20px;">{{ title }}</h4>
    </div>
    <v-spacer/>
    <a :href="link" target="_blank" >{{ link }}</a>
    <!-- <img :src="image" alt="Card Image"> -->
  </div>
</template>

<script>
export default {
  props: {
    year: String,
    title: String,
    link: String
  },
};
</script>

<style  scoped>

/* Gaya kartu Anda di sini */
.card {
  width: 450px;
  height: 450px;
  background-color: #f0f0f0;
  border-radius: 10px;
  overflow: hidden;
  margin: 10px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: flex-start;
  justify-content: start;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  transform: translateX(0);
  transition: transform 0.3s ease;
}
a {
    color: #dd8732;
    font-weight: 500;
    font-size: 14px;
    font-family: 'Outfit', sans-serif !important;
}

/* Animasi ketika kartu masuk atau keluar */
.card-enter-active,
.card-leave-active {
  transition: transform 0.3s ease;
}

.card-enter,
.card-leave-to /* .card-leave-active in <2.1.8 */ {
  transform: translateX(100%);
}
</style>
