<template>
    <div>
        <!-- Start Header Area -->
        <HeaderOnePageTwo class="d-xs-none d-sm-none d-md-flex hidden-mobile" />
        <HeaderOnePageTwoMobile class="d-xs-flex d-sm-flex d-md-none" />
        <!-- End Header Area -->

        <!-- Start Slider Area -->
        <div class="slide slide-style-2 slider-video-bg d-flex align-center justify-center overlay-fix"
            data-black-overlay="1" style="height:30vh !important;">
            <v-container>
                <v-row>
                    <v-col cols="12">
                        <h2 class="heading-title1 text-left font-weight-bold text-capitalize hidden-mobile"
                            style="font-size: 81px !important; line-height: 95px !important; letter-spacing: 2px !important;">
                            Banking & Financial Services
                        </h2>
                        <h2 class="heading-title1 text-left font-weight-bold text-capitalize hidden-md-and-up"
                            style="font-size: 51px !important;">
                            Banking & Financial Services
                        </h2>
                    </v-col>
                    <v-col cols="12" class="inner text-left d-flex flex-column align-start pt-0">
                        <h5 class="heading-title1 heading-subtitle text-h5 font-weight-light hidden-mobile"
                            style="line-height: 40px !important;">
                            Revolutionizing Banking & Financial Services
                        </h5>
                        <h5 class="heading-title1 heading-subtitle text-h5 font-weight-light hidden-md-and-up"
                            style="line-height: 40px !important; font-size: 20px !important;">
                            Revolutionizing Banking & Financial Services
                        </h5>
                    </v-col>
                </v-row>
            </v-container>

            <div class="photo-background hidden-mobile">
                <span>
                    <div style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; background-image: linear-gradient(to bottom, rgba(42, 42, 46, 0.20) 50%, rgb(41, 41, 44));"></div>
                    <img src="https://inplatform.sibernetik.co.id/siber-web/images/banking.jpg" alt=""
                        style="width: 100%;" />
                </span>
            </div>

            <div class="photo-background hidden-md-and-up">
                <span>
                    <div style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; background-image: linear-gradient(to bottom, rgba(42, 42, 46, 0.20) 50%, rgb(41, 41, 44));"></div>
                    <img src="https://inplatform.sibernetik.co.id/siber-web/images/banking.jpg" alt=""
                        style="height: 100%;" />
                </span>
            </div>
        </div>
        <!-- End of Slider -->

        <!-- Start Content -->
        <!-- <div class="rn-contact-area rn-section-gap bg_color--5" style="padding-top:0px; padding-bottom:0px">
            <div class="contact-form--1">
                <v-container>
                    <div class="section rn-blog-area rn-section-gap" id="blog">
                        <div class="section-title section-title--2 text-center mb--20">
                            <h4 class="heading-title">Banking & Financial Services</h4>
                            <h3 class="heading-title mb-5">
                                Lorem Ipsum available
                            </h3>
                            <hr color="#dd8732" width="200px;" style="margin: auto; border-top: 4px  solid #dd8732">
                            <p style="text-align: center;" class="my-8">PSrE-hub is a pioneering system that allows users to process digital signatures for<br>various type of documents safely and seamlessly which can be integrated with various Certificate Authorities.</p>
                        </div>
                        <ServicePsre/>
                    </div>
                </v-container>
            </div>
        </div>   -->
        <div class="rn-contact-area bg_color--1" style="padding-top:0px; padding-bottom:0px">
            <div class="contact-form--1">
                <v-container>
                    <div class="section rn-blog-area rn-section-gap" id="blog">
                        <div class="section-title section-title--2 text-left mb--20">
                            <h4 class="heading-title">Welcome to the future of Banking & Financial Services</h4>
                            <h3 class="heading-title mb-5">
                                a future where technology is the driving force behind every financial transaction, decision,
                                and interaction.
                            </h3>
                            <hr color="#dd8732" style="margin: auto; border-top: 4px  solid #dd8732">
                            <v-row>
                                <v-col lg="12" md="12">

                                    <p style="text-align: left; " class="my-8">
                                        At Sibernetik, we are on a relentless mission to lead this transformation by
                                        providing the most secure, lightning-fast, and exceptionally responsive systems. Our
                                        commitment to this mission stems from an unwavering belief in the power of
                                        technology to
                                        enhance security, efficiency, and customer satisfaction in the financial world.
                                    </p>
                                    <p style="text-align: left; " class="my-8">
                                        In the banking and financial sector, the landscape is dynamic and ever-evolving.
                                        Institutions face an array of challenges, from the constant threat of cyberattacks
                                        to the
                                        need to adapt to changing customer expectations. They must navigate the fine balance
                                        between
                                        delivering cutting-edge digital services and ensuring the safety of sensitive
                                        financial
                                        data. Moreover, customers now demand seamless experiences, whether through online
                                        banking,
                                        mobile apps, or round-the-clock customer support.
                                    </p>
                                    <p style="text-align: left; " class="my-8">
                                        Furthermore, the industry is increasingly complex, marked by stringent regulatory
                                        requirements that demand compliance and data integrity. Technology is advancing at
                                        an
                                        unprecedented pace, and the institutions that thrive are those that can anticipate
                                        and
                                        embrace change.
                                    </p>
                                </v-col>
                            </v-row>
                        </div>
                        <!-- <ServicePsre /> -->
                    </div>
                </v-container>
            </div>
        </div>

        <div class="rn-contact-area bg_color--5" style="padding-top:0px; padding-bottom:0px">
            <div class="contact-form--1">
                <v-container>
                    <div class="section rn-blog-area rn-section-gap" id="blog">
                        <v-row>
                            <v-col>
                                <div class="pr-10">
                                    <h2>Challenges in Banking & Financial Services</h2>
                                    <hr color="#dd8732"
                                        style="margin:auto; margin-bottom: 20px; border-top: 4px solid #dd8732">
                                    <p class="whoweare-ket">
                                        The Banking & Financial Services sector operates in a
                                        dynamic and ever-evolving landscape, marked by both opportunities and challenges.
                                        Institutions within this industry face a myriad of complexities, from cybersecurity
                                        threats to the demands of changing customer expectations, intricate regulatory
                                        requirements, and the rapid pace of technological advancements. In this section, we
                                        delve into these challenges and how Sibernetik stands ready to address
                                        them.
                                    </p>
                                </div>
                            </v-col>
                            <v-col>
                                <v-row>
                                    <v-col lg="6" md="12" sm="12" cols="12" v-for="(service, i) in challenges" :key="i">
                                        <div class="single-service service__style--siber flex"
                                            v-on:mouseenter="onHoverChallenge = service.title"
                                            v-on:mouseleave="onHoverChallenge = ''"
                                            style="display: flex; justify-content: center; align-items: center; padding: 0px !important;">
                                            <v-img :src="service.icon" width="500px" height="300px" />
                                            <div class="bg_color--1 pa-2"
                                                :style="`position: absolute; bottom: 0%; width: 100%;${onHoverChallenge == service.title ? 'color: white; background-color:#dd8732; opacity: 0.9;' : 'opacity: 0.7'}`">
                                                <!-- <div class="icon" v-html="iconSvg(service.icon)"></div> -->
                                                <h4
                                                    :style="`width: 100%; ${onHoverChallenge != service.title ? 'text-align: center' : ''}`">
                                                    {{ service.title }}</h4>
                                                <!-- <div class="content" style="width: 500px;"> -->
                                                <v-expand-transition>
                                                    <p style="font-size: 15px;" v-if="onHoverChallenge == service.title">
                                                        {{ service.desc }}
                                                    </p>
                                                </v-expand-transition>
                                                <!-- </div> -->
                                            </div>
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <!-- End Single Service  -->
                        </v-row>
                    </div>
                </v-container>
            </div>
        </div>

        <div class="rn-portfolio-area rn-section-gap bg_color--1" id="recognitionAwards" style="">
            <div class="portfolio-sacousel-inner pb--30">
                <v-container class="fade-in">
                    <v-row>
                        <v-col lg="12">
                            <div class="section-title section-title--2 text-center mb--20">
                                <h3 class="heading-title mb-5">
                                    Our Banking Solutions
                                </h3>
                                <hr color="#dd8732" width="200px;" style="margin: auto; border-top: 4px  solid #dd8732">
                                <p style="text-align: center; font-size: 24px !important" class="my-8">Over the years, we
                                    Explore our comprehensive suite of tailored solutions designed to empower banking
                                    institutions with speed, security, and innovation.</p>
                            </div>
                        </v-col>
                    </v-row>
                </v-container>
                <div class="card-slider hidden-mobile">
                    <div class="buttons" @click="slideLeft" style="cursor: pointer;">
                        <!-- <v-btn @click="slideLeft" color="transparent" elevation="0" flat> -->
                        <v-icon elevation="0" flat x-large>mdi-chevron-left</v-icon>
                        <!-- </v-btn> -->
                    </div>
                    <transition-group name="card" tag="div" class="card-container">
                        <v-card :class="'card-solutions-' + index" v-for="(card, index) in cards" :key="card.id">
                            <v-img :src="card.image" height="100%" />
                            <div class="bg_color--1 pa-2"
                                :style="`position: absolute; bottom: 0%; width: 100%;${index == 2 ? 'background-color:#dd8732; opacity: 0.9;padding: 20px 20px 60px 20px !important;' : 'opacity: 0.5; padding: 20px 20px 20px 20px !important;'}`">
                                <!-- <div class="icon" v-html="iconSvg(service.icon)"></div> -->
                                <h3 :style="`width: 100%;`">
                                    {{ card.title }}</h3>
                                <!-- <div class="content" style="width: 500px;"> -->
                                <p style="font-size: 15px;" v-if="index == 2">
                                    {{ card.description }}
                                </p>
                                <!-- </div> -->
                            </div>
                        </v-card>
                        <!-- <Card v-for="(card, index) in cards" :key="card.id" :title="card.title" :year="card.year"
                            :link="card.link" style="position: relative;" /> -->
                    </transition-group>
                    <div class="buttons" @click="slideRight" style="cursor: pointer;">
                        <!-- <v-btn @click="slideRight" color="transparent" elevation="0" flat> -->
                        <v-icon elevation="0" flat x-large>mdi-chevron-right</v-icon>
                        <!-- </v-btn> -->
                    </div>
                </div>
                <div class="card-slider hidden-md-and-up" style="position: relative;">
                    <v-btn @click="slideRight" icon
                        style="background-color: #dd8732;cursor: pointer;top: 50%; right: 0%; position: absolute; z-index: 10;">
                        <v-icon elevation="0" flat x-large>mdi-chevron-right</v-icon>
                    </v-btn>
                    <v-btn @click="slideLeft" icon
                        style="background-color: #dd8732;cursor: pointer; top: 50%; left: 0%; position: absolute; z-index: 10;">
                        <v-icon elevation="0" flat x-large>mdi-chevron-left</v-icon>
                    </v-btn>
                    <!-- <div class="buttons" @click="slideLeft(challenges)"
                                    style="cursor: pointer;left: 0%; position: absolute;">
                                    <v-icon elevation="0" flat x-large>mdi-chevron-left</v-icon>
                                </div> -->
                    <v-card
                        :style="`width:100%; height: 500px; transform: translateX(0); transition: transition ease 0.3s; transform: scale(0.9)`">
                        <v-img :src="cards[0].image" height="100%" />
                        <v-btn elevation="0" icon small
                            style="background-color: #dd8732; cursor: pointer; top: 0%; right: 0%; position: absolute; z-index: 10;">
                            {{ cards[0].id }}
                        </v-btn>
                        <div class="bg_color--1 pa-2"
                            :style="`width:auto; height: fit-content; text-break: normal; position: absolute; bottom: 0%; background-color:#dd8732; opacity: 0.9;padding: 20px 20px 60px 20px !important;`">
                            <h3 :style="`width: auto;`">
                                {{ cards[0].title }}</h3>
                            <p style="font-size: 15px;">
                                {{ cards[0].description }}
                            </p>
                        </div>
                    </v-card>
                </div>
            </div>
        </div>
        <div class="rn-contact-area bg_color--5" style="padding-top:0px; padding-bottom:0px">
            <div class="contact-form--1">
                <v-container>
                    <div class="section rn-blog-area rn-section-gap" id="blog">
                        <div class="section-title section-title--2 text-left mb--20">
                            <v-container>
                                <v-row>
                                    <v-col>
                                        <h4 class="heading-title">Partnering for a Secure and Innovative Future</h4>
                                        <h3 class="heading-title mb-5 hidden-mobile">
                                            Transforming Banking and Financial Solutions today, into Tomorrow's Leading
                                            Institutions
                                        </h3>
                                        <h3 class="heading-title mb-5 hidden-md-and-up" style="font-size: 20px;">
                                            Transforming Banking and Financial Solutions today, into Tomorrow's Leading
                                            Institutions
                                        </h3>
                                        <hr color="#dd8732" style="margin: auto; border-top: 4px  solid #dd8732">
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col lg="6" md="12" sm="12">

                                        <div style="position: relative;">
                                            <v-img src="https://inplatform.sibernetik.co.id/siber-web/images/handshake.jpg"
                                                width="auto" class="my-8" height="470px" style="z-index: 0;" />
                                            <v-card
                                                style="position:absolute; width: auto;bottom:0%; z-index: 1; opacity: 0.7; padding: 10px 20px;"
                                                flat tile>
                                                <v-card-title>
                                                    <h4 class="heading-title" style="word-break: normal;">If you're ready to embrace the future
                                                    </h4>
                                                    <h3 class="heading-title hidden-mobile" style="word-break: normal;">
                                                        Defined by technology and innovation
                                                    </h3>
                                                    <h3 class="hidden-md-and-up" style="font-size: 20px; word-break: normal;">
                                                        Defined by technology and innovation
                                                    </h3>
                                                </v-card-title>
                                                <v-card-text>
                                                    <p style="text-align: left;">
                                                        We
                                                        invite
                                                        you to
                                                        join hands with Sibernetik. Together, we'll revolutionize the
                                                        Banking &
                                                        Financial
                                                        Services industry, delivering secure, fast, and responsive systems
                                                        that exceed
                                                        customer
                                                        expectations.
                                                    </p>
                                                </v-card-text>
                                            </v-card>
                                        </div>
                                    </v-col>
                                    <v-col lg="6" md="12" sm="12">

                                        <p style="text-align: left; " class="my-8">
                                            At Sibernetik, we recognize that the challenges faced by the Banking & Financial
                                            Services industry are opportunities for innovation and growth. We believe in
                                            partnering with
                                            institutions to navigate these challenges, ensuring they emerge stronger, more
                                            secure, and
                                            ready for a digitally transformed future.
                                        </p>
                                        <p style="text-align: left; " class="my-8">
                                            Our journey is marked by client success stories, where our solutions have
                                            empowered
                                            institutions to achieve new heights of efficiency, security, and customer
                                            satisfaction. We
                                            are not just technology providers; we are your dedicated partners on the path to
                                            excellence.
                                        </p>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </div>
                    </div>
                </v-container>
            </div>
        </div>
        <!-- End Content -->

        <Footer />
    </div>
</template>
  
<script>
import HeaderOnePageTwo from "../../../components/header/HeaderOnePageTwoSiber.vue";
import HeaderOnePageTwoMobile from "../../../components/header/HeaderOnePageTwoSiberMobile.vue";
import ServicePsre from "../../../components/service/ServicePsre.vue";
import Footer from "../../../components/footer/Footer";
import Card from '../../../components/portfolio/card.vue';

export default {
    components: {
        HeaderOnePageTwo,
        HeaderOnePageTwoMobile,
        ServicePsre,
        Footer,
        Card,
    },
    data: () => ({
        cards: [
            {
                id: 0,
                title: 'Real-time Transaction Processing',
                image: 'https://inplatform.sibernetik.co.id/siber-web/images/transactions.jpg',
                description: 'Our real-time transaction processing solution ensures that your customers can execute transactions swiftly, providing them with a seamless experience.',
            },
            {
                id: 1,
                title: 'Multi-layered Security Protocols',
                image: 'https://inplatform.sibernetik.co.id/siber-web/images/multi-lock.jpg',
                description: 'We implement multi-layered security protocols to safeguard your institution against cyber threats, protecting both your data and your customers.',
            },
            {
                id: 2,
                title: '24/7 Customer Support Integration',
                image: 'https://inplatform.sibernetik.co.id/siber-web/images/customer-service.jpg',
                description: 'Our 24/7 customer support integration ensures that your customers have access to assistance whenever they need it.',
            },
            {
                id: 3,
                title: 'Data Analytics for Informed Decision-Making',
                image: 'https://inplatform.sibernetik.co.id/siber-web/images/dashboard-analytics.jpg',
                description: 'Harness the power of data analytics to make informed decisions that drive your institution\'s success.',
            },
            {
                id: 4,
                title: 'Mobile Banking App Development',
                image: 'https://inplatform.sibernetik.co.id/siber-web/images/mobile-banking.jpg',
                description: 'We specialize in developing cutting-edge mobile banking apps that provide your customers with on-the-go access to their accounts.',
            },
            {
                id: 5,
                title: 'Regulatory Compliance Solutions',
                image: 'https://inplatform.sibernetik.co.id/siber-web/images/legal-regulation.jpg',
                description: 'Stay compliant with ever-changing regulations with our comprehensive compliance solutions.',
            },
            // Tambahkan data kartu selanjutnya
        ],
        onHoverChallenge: '',
        challenges: [
            {
                icon: "https://inplatform.sibernetik.co.id/siber-web/images/cybersecurity.jpg",
                title: "Cybersecurity Threats",
                desc: "Institutions must stay one step ahead of malicious actors to protect customer data and maintain trust."
            },
            {
                icon: "https://inplatform.sibernetik.co.id/siber-web/images/happy-customer.jpg",
                title: "Customer Expectations",
                desc: "They must provide a flawless customer experience that rivals the best in any industry, offering real-time access to accounts, instantaneous transactions, and round-the-clock customer support."
            },
            {
                icon: "https://inplatform.sibernetik.co.id/siber-web/images/labyrinth.jpg",
                title: "Regulatory Compliance",
                desc: "Regulatory compliance remains a top priority, navigating complex, ever-changing regulations while ensuring data is handled in compliance with these rules."
            },
            {
                icon: "https://inplatform.sibernetik.co.id/siber-web/images/banking-technology.jpg",
                title: "Pace of Tech-Change",
                desc: "The pace of technological change continues to accelerate, demanding continual evolution to keep pace with the latest innovations."
            },
        ],
    }),
    methods: {
        slideLeft() {
            // Metode untuk menggeser kartu ke kiri (Previous)
            const lastCard = this.cards.pop();
            this.cards.unshift(lastCard);
        },
        slideRight() {
            // Metode untuk menggeser kartu ke kanan (Next)
            const firstCard = this.cards.shift();
            this.cards.push(firstCard);
        },
    },
    watch: {

    }
};
</script>
<style scoped>
.heading-title1 {
    color: #dd8732 !important;
    line-height: 60px;
    font-family: 'Urbanist', sans-serif !important;
}

.heading-subtitle {
    color: #f7f6f1 !important;
    font-family: 'Urbanist', sans-serif !important;
}

.card-slider {
    grid-row: 1 / 2;
    grid-column: 1 / 8;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.card-container {
    display: grid;
    align-items: center;
    justify-content: center;
    grid-template-columns: repeat(5, 1fr);
    /* Atur jumlah kolom kartu sesuai kebutuhan */
    grid-gap: 10px;
    /* Jarak antar kartu */
    overflow: hidden;
    padding: 20px;
}

.buttons {
    margin-top: 20px;
    text-align: center;
}

.buttons button {
    margin: 5px;
    padding: 10px 20px;
    /* background-color: #007bff; */
    /* color: #fff; */
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.buttons button:hover {
    /* background-color: #0056b3; */
    font-weight: 400;
}

.card-solutions-0 {
    height: 450px;
    width: 450px;
    transform: translateX(0);
    transition: transform 0.3s ease;
    right: -80%;
    z-index: 8;
    transform: scale(0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px !important;
}

.card-solutions-1 {
    height: 450px;
    width: 450px;
    transform: translateX(0);
    transition: transform 0.3s ease;
    right: -30%;
    z-index: 9;
    transform: scale(0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px !important;
}

.card-solutions-2 {
    height: 450px;
    width: 450px;
    transform: translateX(0);
    transition: transform 0.3s ease;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px !important;
}

.card-solutions-3 {
    height: 450px;
    width: 450px;
    transform: translateX(0);
    transition: transform 0.3s ease;
    left: -30%;
    z-index: 9;
    transform: scale(0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px !important;
}

.card-solutions-4 {
    height: 450px;
    width: 450px;
    transform: translateX(0);
    transition: transform 0.3s ease;
    left: -80%;
    z-index: 8;
    transform: scale(0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px !important;
}

.card-solutions-5 {
    height: 450px;
    width: 450px;
    transform: translateX(0);
    transition: transform 0.3s ease;
    display: none;
}
</style>
  